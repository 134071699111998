#offcanvasMenu {
    width: 90vw;
    max-width: 350px;
}

#offcanvasMenu .nav-link {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--red);
}

#offcanvasMenu .nav-link:hover {
    color: var(--black);
}

#offcanvasMenu .nav-sub {
    font-size: .9em;
    font-weight: 700;
    padding: .5em 0;
    color: var(--black);
}

#offcanvasMenu .nav-sub >a  {
    text-decoration: none;
    color: var(--black);
}

#offcanvasBusca {
    height: 20vh;
    max-height: 90px;
}

#offcanvasBusca button,
#offcanvasBusca input {
    border-radius: 0px;
}

body #offcanvasBusca textarea:focus, 
body #offcanvasBusca textarea.form-control:focus, 
body #offcanvasBusca input.form-control:focus, 
body #offcanvasBusca input[type=text]:focus, 
body #offcanvasBusca input[type=password]:focus, 
body #offcanvasBusca input[type=email]:focus, 
body #offcanvasBusca input[type=number]:focus, 
body #offcanvasBusca [type=text].form-control:focus, 
body #offcanvasBusca [type=password].form-control:focus, 
body #offcanvasBusca [type=email].form-control:focus, 
body #offcanvasBusca [type=tel].form-control:focus, 
body #offcanvasBusca [contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border:1px solid #ccc !important;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    #offcanvasBusca {
        height: 170px;
        max-height: unset;
    }
    #offcanvasBusca input {
        margin-bottom: 0.25em;
    }
    #offcanvasBusca button {
        width: 100%;
        margin: 0.12em 0;
    }
}

