/* ad styles */
.ad {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABaADAAQAAAABAAAABQAAAAB/qhzxAAAAFklEQVQIHWN49+7dfxBmQAJMSGxqMQH5zQeV5v98gQAAAABJRU5ErkJggg==);
}

.ad.np {
    background-image: unset;
}

.ad-zone img {
    max-width: 100%;
    height: auto;
}

.ad-zone {
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center!important;
}

.ad-zone.h-90 {
    min-height: 90px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REM3QUY3NTExMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REM3QUY3NTAxMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDA0OTMxMzcxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDA0OTMxMzgxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6hSXXgAAABvElEQVR42uSWv0sCYRjHv0qEk0OD2IFLS+BgcB3l7OAvJDLamrRI78hFCFeHlgyaQhtKWtqEk4gw/4A44pIyaCtE6RKJBpekxd43/FVG5dW59MAL9/K+PN/3+b4f3nt0kiR5RFHcr9fr49AojEbjg9/vX0YsFlPK5XJTy6D5qY6eVmSxWKBl0PxUR48hxr8Vq6K0EYYgHGDnB8ka6RXo0jcqxYoiQpiBi5EQyT39urKRL08qXyDPJXCKEpzyJa7cDkz1bqidIBU/xvXbZAYsp9rGAs7kCayzYwBrx7wi4bDWL7QXTiKZJCP8jII8qlIsl8UmY8eSiXybvAhwd9g67rmPgoQss4i0rTW38djmXtSIETDkGh7nurYZuGnw8nkHlIaiIM+Y39lqYBgVd0bASCh6zO4KED4sRXJzWHOP/R0gFIwUF0UzaO1D+7YNCqnCKVdxBWunOlrtgDZSMEYJWda+FYNvoQuKex4xJYNgse1GCtGBASFgZAjGAdsnu00sPEy5BQoLR9yHFWq1IMB1NInQN4CA5/nmMILqDPdtpH/RSqWiqQjNT3SqOtIWeFttgVnDtuCetAWrrwIMAOi2a8B5U104AAAAAElFTkSuQmCC') no-repeat center center;
}

.ad-zone.h-100 {
    min-height: 90px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REM3QUY3NTExMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REM3QUY3NTAxMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDA0OTMxMzcxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDA0OTMxMzgxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6hSXXgAAABvElEQVR42uSWv0sCYRjHv0qEk0OD2IFLS+BgcB3l7OAvJDLamrRI78hFCFeHlgyaQhtKWtqEk4gw/4A44pIyaCtE6RKJBpekxd43/FVG5dW59MAL9/K+PN/3+b4f3nt0kiR5RFHcr9fr49AojEbjg9/vX0YsFlPK5XJTy6D5qY6eVmSxWKBl0PxUR48hxr8Vq6K0EYYgHGDnB8ka6RXo0jcqxYoiQpiBi5EQyT39urKRL08qXyDPJXCKEpzyJa7cDkz1bqidIBU/xvXbZAYsp9rGAs7kCayzYwBrx7wi4bDWL7QXTiKZJCP8jII8qlIsl8UmY8eSiXybvAhwd9g67rmPgoQss4i0rTW38djmXtSIETDkGh7nurYZuGnw8nkHlIaiIM+Y39lqYBgVd0bASCh6zO4KED4sRXJzWHOP/R0gFIwUF0UzaO1D+7YNCqnCKVdxBWunOlrtgDZSMEYJWda+FYNvoQuKex4xJYNgse1GCtGBASFgZAjGAdsnu00sPEy5BQoLR9yHFWq1IMB1NInQN4CA5/nmMILqDPdtpH/RSqWiqQjNT3SqOtIWeFttgVnDtuCetAWrrwIMAOi2a8B5U104AAAAAElFTkSuQmCC') no-repeat center center;
}

.ad-zone.h-300 {
    /* min-height: 300px; */
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REM3QUY3NTExMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REM3QUY3NTAxMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDA0OTMxMzcxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDA0OTMxMzgxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6hSXXgAAABvElEQVR42uSWv0sCYRjHv0qEk0OD2IFLS+BgcB3l7OAvJDLamrRI78hFCFeHlgyaQhtKWtqEk4gw/4A44pIyaCtE6RKJBpekxd43/FVG5dW59MAL9/K+PN/3+b4f3nt0kiR5RFHcr9fr49AojEbjg9/vX0YsFlPK5XJTy6D5qY6eVmSxWKBl0PxUR48hxr8Vq6K0EYYgHGDnB8ka6RXo0jcqxYoiQpiBi5EQyT39urKRL08qXyDPJXCKEpzyJa7cDkz1bqidIBU/xvXbZAYsp9rGAs7kCayzYwBrx7wi4bDWL7QXTiKZJCP8jII8qlIsl8UmY8eSiXybvAhwd9g67rmPgoQss4i0rTW38djmXtSIETDkGh7nurYZuGnw8nkHlIaiIM+Y39lqYBgVd0bASCh6zO4KED4sRXJzWHOP/R0gFIwUF0UzaO1D+7YNCqnCKVdxBWunOlrtgDZSMEYJWda+FYNvoQuKex4xJYNgse1GCtGBASFgZAjGAdsnu00sPEy5BQoLR9yHFWq1IMB1NInQN4CA5/nmMILqDPdtpH/RSqWiqQjNT3SqOtIWeFttgVnDtuCetAWrrwIMAOi2a8B5U104AAAAAElFTkSuQmCC') no-repeat center center;
}

.ad-zone.h-250 {
    min-height: 250px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REM3QUY3NTExMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REM3QUY3NTAxMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDA0OTMxMzcxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDA0OTMxMzgxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6hSXXgAAABvElEQVR42uSWv0sCYRjHv0qEk0OD2IFLS+BgcB3l7OAvJDLamrRI78hFCFeHlgyaQhtKWtqEk4gw/4A44pIyaCtE6RKJBpekxd43/FVG5dW59MAL9/K+PN/3+b4f3nt0kiR5RFHcr9fr49AojEbjg9/vX0YsFlPK5XJTy6D5qY6eVmSxWKBl0PxUR48hxr8Vq6K0EYYgHGDnB8ka6RXo0jcqxYoiQpiBi5EQyT39urKRL08qXyDPJXCKEpzyJa7cDkz1bqidIBU/xvXbZAYsp9rGAs7kCayzYwBrx7wi4bDWL7QXTiKZJCP8jII8qlIsl8UmY8eSiXybvAhwd9g67rmPgoQss4i0rTW38djmXtSIETDkGh7nurYZuGnw8nkHlIaiIM+Y39lqYBgVd0bASCh6zO4KED4sRXJzWHOP/R0gFIwUF0UzaO1D+7YNCqnCKVdxBWunOlrtgDZSMEYJWda+FYNvoQuKex4xJYNgse1GCtGBASFgZAjGAdsnu00sPEy5BQoLR9yHFWq1IMB1NInQN4CA5/nmMILqDPdtpH/RSqWiqQjNT3SqOtIWeFttgVnDtuCetAWrrwIMAOi2a8B5U104AAAAAElFTkSuQmCC') no-repeat center center;
}

.ad-zone.h-600 {
    min-height: 600px;    
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REM3QUY3NTExMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REM3QUY3NTAxMTFFMTFFQUJBNjBGRDMzODE5Q0Q4OEUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDA0OTMxMzcxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDA0OTMxMzgxMDVDMTFFQTkzMzI4MUFDMDc5Rjg4MkIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6hSXXgAAABvElEQVR42uSWv0sCYRjHv0qEk0OD2IFLS+BgcB3l7OAvJDLamrRI78hFCFeHlgyaQhtKWtqEk4gw/4A44pIyaCtE6RKJBpekxd43/FVG5dW59MAL9/K+PN/3+b4f3nt0kiR5RFHcr9fr49AojEbjg9/vX0YsFlPK5XJTy6D5qY6eVmSxWKBl0PxUR48hxr8Vq6K0EYYgHGDnB8ka6RXo0jcqxYoiQpiBi5EQyT39urKRL08qXyDPJXCKEpzyJa7cDkz1bqidIBU/xvXbZAYsp9rGAs7kCayzYwBrx7wi4bDWL7QXTiKZJCP8jII8qlIsl8UmY8eSiXybvAhwd9g67rmPgoQss4i0rTW38djmXtSIETDkGh7nurYZuGnw8nkHlIaiIM+Y39lqYBgVd0bASCh6zO4KED4sRXJzWHOP/R0gFIwUF0UzaO1D+7YNCqnCKVdxBWunOlrtgDZSMEYJWda+FYNvoQuKex4xJYNgse1GCtGBASFgZAjGAdsnu00sPEy5BQoLR9yHFWq1IMB1NInQN4CA5/nmMILqDPdtpH/RSqWiqQjNT3SqOtIWeFttgVnDtuCetAWrrwIMAOi2a8B5U104AAAAAElFTkSuQmCC') no-repeat center center;
}

.mw-300 {
    max-width: 300px;
}

@media screen and (max-width: 1200px) {
    [class*="ad-zone"] ins[data-revive-id] img {
        max-width: 100%;
        height: auto;
    }
}