.A2 .titulo,
.A3 .titulo,
.A4 .titulo,
.AX.AN .titulo {
    color: var(--dark);
    font-weight: 700;
}

.A2 .AX.SM span {
    font-size: 60%;
}
.A2 .AX.SM .titulo {
    font-size: 60%;
    font-weight: normal;
}

.A2 .titulo {
    font-size: 1em;
}

.A3 .titulo {
    font-size: 85%;
    font-weight: 700;
}

.A2 img {
    margin-bottom: .5em;
    height: 40vh;
    max-height: 350px;
    width: 100%;
}

.AX.AN .titulo {
    font-size: 60%;
    font-weight: 700;
}

.AX.AN img {
    width: 120px;
    height: 80px;
}

.AX.AN .dt {
    font-size: 60%;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    .A2 .AX .titulo {
        font-size: 90%;
    }
    .A2 img {
        height: auto;
        max-height: unset;
        width: 100%;
    }
}
