.A1 .titulo {
    color: var(--dark);
    font-weight: 700;
    font-size: 1.8em;
}

.A1 .subtitulo {
    color: var(--dark);
    font-weight: 400;
    font-size: 80%;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    .A1 .titulo {
        font-size: 1.2em;
    }
    .A1 .subtitulo {
        font-size: 70%;
    }
}