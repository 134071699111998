#swiperColunistas img {
    max-width: 80px;
    max-height: 80px;
}

#swiperColunistas .chapeu {
    font-size: 55%;
}

#swiperColunistas .chapeu::after {
    content: '\00000a ';
    white-space: pre; /* or pre-wrap */
}

#swiperColunistas .titulo {
    font-size: 65%;
    font-weight: 600;
    color: #ffffff;
}

#swiperColunistas .h2 {
    font-weight: 700;
    font-size: 90%;
    text-transform: uppercase;
}

#swiperColunistas .h2 > a {
    color: #ffffff;
    text-decoration: none;
}

#swiperColunistas .fs-6 {
    font-size: 60% !important;
}