.FaleConosco {
    font-size: 0.9em;
}

.FaleConosco a {
    color: var(--red);
}

.FaleConosco label,
.FaleConosco input,
.FaleConosco textarea {
    font-size: 0.8em;
}

.FaleConosco [role="alert"] {
    font-size: 0.8em;
    font-weight: 600;
}