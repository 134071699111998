#swiperImagens {
    min-height: 460px;
    background-color: #000000;
}

#swiperImagens img {
    height: 460px;
    width: 100%;
}

#swiperImagens .swiper-button-prev, 
#swiperImagens .swiper-button-next {
    color: var(--white, var(--white)) !important;
}

#swiperImagens .swiper-scrollbar {
    background: #666 !important;
}

#swiperImagens .swiper-scrollbar-drag {
    background: var(--white, #FFFFFF);
}

#swiperImagens figcaption {
    font-size: 60%;
    color: #ffffff;
    padding: 0.2em 0.5em 0 0.5em;
}

#swiperImagens .figure {
    margin: 0;
    margin-bottom: 20px;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    #swiperImagens {
        min-height: unset;
    }
    
    #swiperImagens img {
        height: unset;
        width: auto;
        max-width: 100%;
    }
}

