.Internas.Editoria {
    font-size: 90%;
}

.Internas.Editoria .h2-title {
    font-size: 1.7em;
    font-weight: 700;
}

.Internas.Editoria img {
    height: 250;
}

.Internas.Editoria .title {
    font-size: 1em;
    font-weight: 700;
}

.Internas.Editoria .summary {
    font-size: 85%;
    font-weight: 400;
}

.Internas.Editoria h2,
.Internas.Editoria h3,
.Internas.Editoria h4,
.Internas.Editoria h5,
.Internas.Editoria h6 {
    font-size: 1.2em;
}

.Internas.Editoria .h1-search {
    font-size: 1em;
    text-align: center;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    .Internas.Editoria .h2-title {
        font-size: 1.4em;
    }    
}
