@import "bootstrap/scss/bootstrap";
@import "../../node_modules/freezeui/freeze-ui.css";
@import url(root.css);
@import url(ad.css);
@import url(offcanvas.css);
@import url(A1.css);
@import url(Ax.css);
@import url(swiperColunistas.css);
@import url(swiperImagens.css);
@import url(podcasts_entrevistas.css);
@import url(editoria.css);
@import url(materia.css);
@import url(w-ultimas.css);
@import url(faleconosco.css);

html, body {
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
}

.chapeu {
    color: var(--red);
    font-size: 65%;
    font-weight: 700;
    padding-bottom: 0.3em;
}


hr {
    border-top: 1px dotted  #333333;
}


.h2-title {
    color: var(--red);
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
}

.h2-title > a {
    color: var(--red);
    text-decoration: none;
}

.h2-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    margin-top: 5px;
    border: 2px solid var(--red);
}

.h2-title.white,
.h2-title.white > a{
    color: var(--white);
}

.h2-title.white::after {
    border: 2px solid var(--white);
}

footer {
    font-size: 80%;
    font-weight: 600;
}

.col-mw-300 {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden;
}

.mw-300 {
    width: 300px;
    min-width: 300px;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    .col-mw-300 {
        width: 100%;
        max-width: 100%;
        min-width: unset;
        overflow: unset;
    }
}

.break-line-after::after {
    content: '\00000a ';
    white-space: pre; /* or pre-wrap */
}

// .shimmer:not(.entered) {
//     color: grey;
//     /* display:inline-block; */
//     -webkit-mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
//     background-repeat: no-repeat;
//     animation: shimmer 1.5s infinite;
//     font-size: 50px;
//     /* max-width:200px; */
// }

.shimmer:not(.entered)  {
    color: grey;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
    // max-width:200px;
  }
  
  @keyframes shimmer {
    100% {-webkit-mask-position:left}
  }

.page-link {
    color: var(--dark);
    text-decoration: none;
    background-color: #fff;
    border-color: var(--dark) !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 0.7rem !important;
}

.page-link:hover {
    color: var(--dark);
}

.page-item.active .page-link {
    background-color: var(--dark);
    color: #fff;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

