.w-ultimas .h2-title {
    font-size: 1em;
}

.w-ultimas .titulo {
    font-weight: 700 !important;
    font-size: 0.65em !important;
}

.w-ultimas .chapeu {
    font-size: 70%;
    font-weight: 700;
    color: #e9a600;
    font-style: italic;
}

.w-ultimas img {
    
}