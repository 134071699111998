.Internas.Materia h1 {
    font-size: 2em;
}

.Internas.Materia,
.Internas.Materia p {
    font-size: 0.9em;
}

.Internas.Materia .h2-title {
    text-transform: none;
    font-size: 0.9em;
}

.Internas.Materia .subtitle {
    font-size: 0.95em;
    font-weight: thin;
    font-style: italic;
    color: #666;
}

.Internas.Materia .figure > img {
    max-width: 100%;
    height: auto;
}

.Internas.Materia .publishdt {
    font-size: 0.7em;
    font-weight: 600;
    color: #333;
}

.Internas.Materia figcaption {
    font-size: 70%;
    font-style: italic;
    text-align: right;
}

.Internas.Materia .related {
    font-size: 85%;
}

.Internas.Materia .related .block2 {
    font-size: 90%;
}

.Internas.Materia .related .h2-title {
    font-size: 100%;
    text-transform: uppercase;
}

.Internas.Materia .related .title {
    font-size: 80%;
    font-weight: 600;
    color: var(--black);
}

.Internas.Materia .related img {
    width: 100%;
    height: 120px;
}

.Internas.Materia .related .summary {
    font-weight: 400;
    font-size: 80%;
}

.Internas.Materia .tags .h2 {
    font-size: 90%;
    font-weight: 700;
    font-style: italic;
}

.Internas.Materia .sharethis-inline-share-buttons {
    min-height: 30px;
}

.Internas.Materia h2,
.Internas.Materia h3,
.Internas.Materia h4,
.Internas.Materia h5,
.Internas.Materia h6 {
    font-size: 1em;
    font-weight: 700;
}

.Internas.Materia .HTML figure.media::before,
.Internas.Materia  .HTML figure.media::after,
.Internas.Materia  .HTML .raw-html-embed::before,
.Internas.Materia  .HTML .raw-html-embed::after {
    display: block;
    content: "";
    clear: both;
}

.Internas.Materia  .HTML figure.image {
    display: table;
    clear: both;
    text-align: center;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin-left: auto;
    margin-right: auto;
}

.Internas.Materia  .HTML figure.image.destacada {
    margin-top: unset !important;
}

.Internas.Materia  .HTML figure > picture > img {
    width: unset !important;
    height: unset !important;
}

.Internas.Materia img.fslightbox {
    cursor: pointer;
}

.Internas.Materia  .HTML figure.image.w-100 > img {
    width: 100%;
}
  
.Internas.Materia  .HTML figure.image-style-align-left {
    float: left;
    margin: 0;
    margin-right: .5rem;
    margin-bottom: 0;
    clear: both;
    content: "";
}

.Internas.Materia  .HTML figure.image-style-align-right {
    float: right;
    margin: 0;
    margin-left: .5rem;
    margin-bottom: 0;
    clear: both;
    content: "";
}

.Internas.Materia  .HTML img.image-style-align-left {
    float: left;
    margin: 0;
    margin-right: .5rem;
    margin-bottom: 0;
    clear: both;
}

.Internas.Materia  .HTML img.image-style-align-right {
    float: right;
    margin: 0;
    margin-left: .5rem;
    margin-bottom: 0;
    clear: both;
}

.Internas.Materia  .HTML img.shimmer.no-full-width {
    height: auto;
}

.Internas.Materia  .HTML figure.image img {
    max-width: 100%;
    height: auto;
}

.Internas.Materia  .HTML figure.image figcaption {
    display: table-caption;
    caption-side: bottom;
}

.Internas.Materia  .HTML figure.image.image_resized {
    /* max-width: 100%; */
    max-width: 934px;
    display: block;
    box-sizing: border-box;
}

.Internas.Materia  .HTML img.image_resized {
    width: unset;
    height: auto;
}

.Internas.Materia  .HTML figure.image.image_resized > figcaption {
    display: block;
}

.Internas.Materia .embed-soundcloud > iframe {
    max-height: 166px !important;
}
  
.Internas.Materia blockquote:not(.fb-xfbml-parse-ignore):not(.instagram-media)::after{
    content: "";
}
  
.Internas.Materia blockquote:not(.fb-xfbml-parse-ignore):not(.instagram-media) em{
    font-style: italic;
    font-weight: 400 !important;
}

.Internas.Materia figure.audio::before {
    content: "Ouça o áudio";
    display: block;
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: var(--red);
}

.Internas.Materia figure.audio,
.Internas.Materia audio {
    width: 100%;
    max-width: 100%;
}

@media screen and (max-width: 40em) and (orientation: portrait) {

    .Internas.Materia .title {
        font-size: 1.5em;
    }

    .Internas.Materia .subtitle {
        font-size: 1em;
    }

    .Internas.Materia .related img {
        width: 90px;
        height: auto;
    }
    .Internas.Materia h2,
    .Internas.Materia h3,
    .Internas.Materia h4,
    .Internas.Materia h5,
    .Internas.Materia h6 {
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
    }

    .Internas.Materia .related .title {
        font-size: 1em;
        font-weight: 400;
    }
    
    .Internas.Materia .HTML p {
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
    }    
}


@media print {
    body {
        font-size: 14px;
    }
    .Internas.Materia .col-lg-8 {
        width: 100%;
        max-width: 100%;
    }
    .Internas.Materia .border-end {
        border: unset !important;
    }
    .Internas.Materia .editoria > a {
        font-size: 0.7rem;
    }
    .Internas.Materia .title {
        font-size: 1.3rem;
        line-height: 1.3;
    }
    .Internas.Materia .HTML,
    .Internas.Materia .HTML p {
        font-size: 90%;
        line-height: normal;
    }
    div[id^="tbl"] {
        display: none !important;
    }
    [data-revive-id], #onesignal-slidedown-dialog {
        display: none;
    }
    .google-news-container {
        display: none;
    }
    .Internas.Materia .fb-post {
        max-width: 100%;
    }
    .Internas.Materia .fb-video, 
    .Internas.Materia audio,
    .Internas.Materia video,
    .Internas.Materia .pdf_view {
        display: none;
    }
    img#logo {
        -webkit-filter: invert(1);
        filter: invert(1);
        width: auto;
        height: 60px;
        margin-bottom: 50px;
    }
}
